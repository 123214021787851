@import "./imports";

html, body, #root, .App {
  width: 100%;
  display: block;
}

body {
  margin: 0;
  font-family: BuckeyeSans,Open Sans,-apple-system,BlinkMacSystemFont,"Segoe UI",Roboto,"Helvetica Neue",Arial,sans-serif,"Apple Color Emoji","Segoe UI Emoji","Segoe UI Symbol";
  letter-spacing: normal;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  --nav-height: 64px;
  letter-spacing: normal;
  overscroll-behavior: contain
}

#osunavbar {
  position: relative;
}

@keyframes shake {
  0% {
    transform: translateX(0);
  }
  94% {
    transform: translateX(0);
  }
  95% {
    transform: translateX(-2px);
  }
  96% {
    transform: translateX(-4px);
  }
  97% {
    transform: translateX(4px);
  }
  98% {
    transform: translateX(-4px);
  }
  99% {
    transform: translateX(4px);
  }
  100% {
    transform: translateX(2px);
  }
}

.font-icon-wrapper {
  display: inline-block;
  animation: shake 12s 5;
}

@media (prefers-reduced-motion) {
  .font-icon-wrapper {
    animation: none
  }
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
  monospace;
}

#root {
  background-color: var(--root-background-color);
  color: var(--root-text-color);
}

#osunavbar {
  position: relative;
}

table {
  table-layout: fixed;
}

html, body, #root {
  height: 100%;
}

main, h1, h2 {
  &:focus {
     outline: none
  }
}

address {
  font-style: normal;
}

.object-fit-none {
  object-fit: none !important;
}

details > summary {
  list-style: none;
}
details > summary::marker, /* Latest Chrome, Edge, Firefox */ 
details > summary::-webkit-details-marker /* Safari */ {
  display: none;
}

.border-none {
  border: none !important;
}

.dark-mode-enabled {

  input::placeholder {
    color: var(--root-text-color);
    opacity: .9
  }
  
  input::-ms-input-placeholder { 
    color: var(--root-text-color);
    opacity: .9
  }
}
footer .contactLinkContainer {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
}

footer .contactLink {
  display: block;
  margin-top: .5rem;
  margin-bottom: .5rem
}

.font-weight-bold {
  font-weight: bold;
}