.buckeye-bot-paper {
  --chatbot-overall-height: 39rem; /* Update matching media query if changed*/
  --chatbot-width: 25rem;
  --chatbot-header-height: 4rem;
  width: var(--chatbot-width);
  height: var(--chatbot-overall-height);
  z-index: 10;
  bottom: 0;
  max-height: 100%;
  position: fixed;
  bottom: 0;
  right: 20px;
  transition: all 0.3s ease-out;
  cursor: pointer;
  z-index: 99999;
}
.buckeye-bot-paper, .buckeye-bot-paper * {
  font-family: BuckeyeSans,Open Sans,-apple-system,BlinkMacSystemFont,"Segoe UI",Roboto,"Helvetica Neue",Arial,sans-serif,"Apple Color Emoji","Segoe UI Emoji","Segoe UI Symbol";
  letter-spacing: normal;
}
/* 624px based on  var(--chatbot-overall-height)*/
.buckeye-chat-heading {
  width: 100%;
  height: var(--chatbot-header-height);
}
.buckeye-bot-paper .buckeye-chat-container {
  width: 100%;
  height: calc(var(--chatbot-overall-height) - var(--chatbot-header-height))
}
#buckeye-chat-action-header {
  width: 100%;
}
#buckeye-chat-action-header svg {
  width: 1rem;
  height: 1rem;
}

@media screen and (max-width: 600px), (max-height: 39rem) {
  #buckeye-chat-action-header svg {
    width: 3em;
    height: 3em;
  }
  .buckeye-bot-paper {
    --chatbot-overall-height: 100%;
    --chatbot-header-height: 10vh;
    width: 100%;
    height: 100%;
    margin: 0;
    right: 0;
    left: 0;
    top: 0;
    bottom: 0;
  }
}

@media only screen and (max-width: 320px) {
  .buckeye-bot-paper {
      --chatbot-header-height: 0px
  }
  #buckeye-chat-action-header svg {
    width: auto;
    height: 70%;
    margin: 0 auto;
  }
  .buckeye-bot-paper button {
    display: flex;
    align-items: center;
  }
  #buckeye-chat-action-header {
      width: 44px;
      height: 44px;
      min-width: auto;
      position: absolute;
      top: 0;
      right: 0;
      margin: 0;
      padding: 0;
      border-radius: 0;
  }
  #buckeye-chat-action-header .full-heading {
      display: none;
  }
  button#buckeye-chat-action-header {
      font-size: 1em;
  }
}